@font-face {
  font-family: "Roboto-Black";
  src: url("assets/fonts/Roboto/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("assets/fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "RobotoCondensed-Bold";
  src: url("assets/fonts/Roboto/RobotoCondensed-Bold.ttf");
}
@font-face {
  font-family: "RobotoCondensed-Medium";
  src: url("assets/fonts/Roboto/RobotoCondensed-Medium.ttf");
}
@font-face {
  font-family: "RobotoCondensed-Regular";
  src: url("assets/fonts/Roboto/RobotoCondensed-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("assets/fonts/Roboto/Roboto-Italic.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("assets/fonts/Roboto/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf");
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
}
.Roboto-Black {
  font-family: "Roboto-Black" !important;
}
.Roboto-Bold {
  font-family: "Roboto-Bold" !important;
}
.RobotoCondensed-Bold {
  font-family: "RobotoCondensed-Bold" !important;
}
.RobotoCondensed-Medium {
  font-family: "RobotoCondensed-Medium" !important;
}
.RobotoCondensed-Regular {
  font-family: "RobotoCondensed-Regular" !important;
}
.Roboto-Italic {
  font-family: "Roboto-Italic" !important;
}
.Roboto-Medium {
  font-family: "Roboto-Medium" !important;
}
.Roboto-Regular {
  font-family: "Roboto-Regular" !important;
}
.Roboto-Medium-Italic {
  font-family: "Roboto-Medium-Italic" !important;
}
@font-face {
  font-family: "Raleway-Black";
  src: url("assets/fonts/Raleway/Raleway-Black.ttf");
}
@font-face {
  font-family: "Raleway-Bold";
  src: url("assets/fonts/Raleway/Raleway-Bold.ttf");
}
@font-face {
  font-family: "Raleway-Light";
  src: url("assets/fonts/Raleway/Raleway-Light.ttf");
}
@font-face {
  font-family: "Raleway-Medium";
  src: url("assets/fonts/Raleway/Raleway-Medium.ttf");
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("assets/fonts/Raleway/Raleway-Regular.ttf");
}
@font-face {
  font-family: "Raleway-Thin";
  src: url("assets/fonts/Raleway/Raleway-Thin.ttf");
}
@font-face {
  font-family: "Raleway-ExtraBold";
  src: url("assets/fonts/Raleway/Raleway-ExtraBold.ttf");
}
@font-face {
  font-family: "Raleway-SemiBold";
  src: url("assets/fonts/Raleway/Raleway-SemiBold.ttf");
}
.side-bar-scroll:-webkit-scrollbar {
  width: 7px;
}
.side-bar-scroll::-webkit-scrollbar-track {
  background: #3026f1;
}
.side-bar-scroll::-webkit-scrollbar-thumb {
  background: #191847;
  border-radius: 5px !important;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px #191847ae, 0px 4px 5px 0px #00000024,
    0px 1px 10px 0px #191847b4;
}
body{
  /* transition: 1s; */
}
.body::-webkit-scrollbar {
  width: 7px;
}
body::-webkit-scrollbar-track {
  background: white;
}
body::-webkit-scrollbar-thumb {
  background: #3026f1;
  border-radius: 0;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 100;
}
.padding-0 {
  padding: 0px;
}
.padding-5 {
  padding: 5px;
}
.padding-10 {
  padding: 10px;
}
.padding-20 {
  padding: 20px;
}
.selected-row {
  /* border: 1px solid red; */
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}
.unselected-row {
  /* border: 1px solid red; */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
td {
  border-bottom: none !important;
}
.text_feild_small {
  height: 36px !important;
}
.disabled-bg {
  background-color: #efefef !important;
}
.ps__rail-y {
  display: none !important;
}
.border-bottom-black {
  border-bottom: 1px solid #3e3e3e;
}
/*
====================================================================
  Loading Transition
====================================================================
 */
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #000000bd;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
  text-align: center;
}
.ctn-preloader .animation-preloader .icon {
  display: inline-block;
  position: relative;
}
.ctn-preloader .animation-preloader .txt-loading {
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #f9c9b3;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 30px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "Roboto-Regular";
  letter-spacing: 12px;
  display: inline-block;
  color: #ee6931;
  position: relative;
  font-size: 50px;
  line-height: 30px;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}
.MuiOutlinedInput-root {
  height: 45px !important;
  background-color: #fff;
}
.css-9pju5x-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff;
}
.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; 
  white-space: pre-wrap; 
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.App{
  width: 100%;
  
}
.App-map{
  height: 400px;
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.new-message-box {
  margin: 15px;
  height: 10px;
  padding-left: 20px;
  margin-bottom: 25px !important;
  cursor: pointer;

}
.new-message-box p {
  font-size: 18px;
 }
.info-tab {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  top: 8px;
}
.info-tab {
  float: left;
  margin-left: -23px;
}
.info-tab i::before {
  width: 24px;
  height: 24px;
  box-shadow: inset 12px 0 13px rgba(0, 0, 0, 0.5);
}
.info-tab i::after {
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  bottom: -18px;
}
.info-tab i::before,
.info-tab i::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -17px;
  transform: rotateX(60deg);
}
.tip-box-alert {
  padding: 12px 8px 3px 26px;
}
 .new-message-box-alert {
   padding: 3px;
  margin: 10px 0;
}
.tip-box-alert {
  color: #212121;
  background: #fff8e1;
  height: 80px;
  box-shadow: 0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024,
  0px 1px 10px 0px #0000001f;
  border: 1px solid #f5c0aa;
}
.tip-icon-alert {
  background: #ee6931;
}
.tip-icon-alert i::before {
  background: #ee6931;
}
#react-confirm-alert{
  z-index: 999999;
  position: fixed;
}
.react-confirm-alert-overlay{
  background-color: rgba(0, 0, 0, 0.616)!important;
  background:rgba(0, 0, 0, 0.616)  !important;
  
}
.css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-toolbar{
  color:'white'
}
.css-m87zkl-MuiInputBase-input-MuiOutlinedInput-input{
  background-color: none !important;
}
.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title{
  color:#fff
}
.f-gray-scale {
  filter: grayscale(100);
  width: 35px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
  transition: 1s;
  border-radius: 4;
  margin-right: 5px;
  margin-top: 2px;
  background:none;
}
.disable_f_gray_scale{
  filter: grayscale(100);
  width: 35px;
  height: 40px;
  object-fit: contain;
  background:none;
  margin-right: 5px;
  margin-top: 2px;

}
.f-gray-scale:hover {
  filter: grayscale(0);
  transform: scale(1.1);
  transition: 1s;
}
.accordion-content {
  max-height: 0;
  padding: 0 1em;
  background: white;
  transition: all 0.35s;
}
input:checked ~ .accordion-content {
  max-height: 100vh;
  padding: 1em;
}

